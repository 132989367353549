import React from 'react'

import Layout from '../components/Layout'

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
    paragraph: {
        paddingBottom: "2rem",
    },
}));

export const PrivacyPageTemplate = () => {
    
    const classes = useStyles();

    return(
    <Container>
        <Typography variant="h3" component="h1" className={classes.paragraph}> Privacy Policy</Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Last Updated November 21, 2020
        </Typography>

        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity is committed to protecting your privacy and providing you a safe online experience. This Privacy Policy describes the information 
            practices, policies and procedures of websites and other online services owned and/or operated by DanceClarity and its affiliates (“DanceClarity”) 
            including www.danceclarity.com, app.danceclarity.com, and dev.danceclarity.com (the “Websites”). Use of "you" herein shall mean both personal users and business users, and use of “us” shall mean DanceClarity. By visiting 
            the Websites, you are accepting all of the practices, policies and procedures described herein, and you are further indicating that you agree 
            to be bound by the terms of this Privacy Policy. If you do not agree, please do not visit or discontinue use of the Websites.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Collection of Personal Information
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            When you use the DanceClarity services, you voluntarily provide us with some or all of the following personal information: name, address, 
            e-mail address, dance history, contacts, phone number, job title, company name and company website URL (“Personal Information”). 
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You agree that you may use the DanceClarity Websites as either a personal user (a "student") and a business user (a "studio"). If using the
            Websites as a studio, you agree that you will only provide information and use the Websites while acting as an employee, owner, director, 
            officer, or contractor of a company, partnership, sole proprietorship, nonprofit or other legal entity, and that your communications and 
            transactions with DanceClarity occur solely within the context of DanceClarity providing the Websites to the company, 
            partnership, sole proprietorship, nonprofit or other legal entity that you represent. If using the Websites as a student, you agree that you will 
            only provide information and use the Websites while acting on your own behalf.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity or its service providers automatically receive and collect information about your computer hardware and software when you visit the 
            Websites. This information can include: your IP address, unique identifier cookies, browser type, domain names, usage information, including access 
            times, pages visited, referring Website addresses and similar data. This information is used by DanceClarity in the aggregate for the operation 
            of the services, to maintain quality of the services, and to provide general statistics regarding use of the Websites. For more information 
            about these online tools and how we use them, see the “Use of Cookies” and “Disclosure to Third Parties” section below.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Use of Personal Information
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity collects and uses your personal information to operate the DanceClarity Websites and deliver the services you have requested. DanceClarity 
            may also use personal information to inform you of other products or services available from DanceClarity or third parties.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity may utilize your collected personal information in one of the following ways: to personalize your experience; to improve the Websites; to 
            improve customer service; to process transactions; to send or receive periodic emails and other communications; and to provide your personal information to
            studios.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Disclosure to Third Parties
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity takes your privacy seriously. DanceClarity does not send personal user-level information to advertisers and/or partners. DanceClarity 
            does not sell, trade, or otherwise transfer to third parties your personal information without your permission. Information you provide in your public 
            profile, however, may be shown to other users -- both students and studios -- of the Websites as a part of your public profile.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity discloses personal information to trusted third parties who assist us in operating our Websites, conducting our business, or servicing 
            you, so long as those parties agree to keep this information confidential and comply with applicable privacy laws. DanceClarity may share data with 
            trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for other services 
            provided by the Websites. All such third parties are prohibited from using your personal information except to provide these services to DanceClarity.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity does not sell, rent or lease its customer lists to third parties. DanceClarity may, from time to time, contact you on behalf of external 
            business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, 
            name, address, telephone number) is not transferred to the third party.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Parley Pro may also release your information when we believe release is appropriate to comply with the law, enforce our Website policies, or protect 
            ours or others’ rights, property, or safety.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity’s advertising partners provide contextual targeting services that solely rely on cookie-level tracking which contains no identifying 
            information. DanceClarity may provide anonymized information to other parties for marketing, advertising, or other uses.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Third-party websites
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity sometimes provides links to third-party websites as a service to you to provide you additional information related to the Services, the 
            services of the third-party, or additional sites in which you can learn about and discuss DanceClarity Services. DanceClarity has no affiliation 
            with these third parties, and does not control and is not responsible for the handling of information collected or used by such third parties. DanceClarity 
            is not responsible for the content of these websites, any products or services that may be offered through them.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Protection of Personal Information
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Parley Pro attempts to secure your personal information from unauthorized access, use or disclosure by implementing a variety of security measures to maintain the 
            safety of your personal information. However, DanceClarity does not ensure the security of any personal information provided to DanceClarity 
            or communications made by you through the Websites, and you understand, agree and acknowledge that DanceClarity cannot and does not guarantee the 
            security of such information or communications transmitted over the Internet or public 
            networks in connection with your use of the Website and services.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Use of Cookies
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            The Websites may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a webpage 
            server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a 
            web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time 
            by telling the Web server that you have returned to a specific page. For example, if you register with DanceClarity, a cookie helps us recall your 
            specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping 
            addresses, etc. Cookies also track user movements in the Websites in the aggregate and compile general demographic information for aggregate use. 
            Cookies help us learn how our users are consuming the services so that we may enhance the Websites or for other lawful purposes.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify browser settings to decline 
            cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the DanceClarity Websites.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Do Not Track
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Do Not Track is a technology that provides individual users of the Internet with the ability to include a machine-readable header indicating that they 
            do not want to be tracked which sends a signal to websites visited requesting that the website disable either its tracking or cross-site user tracking 
            of an individual user. There is currently no common or accepted industry standard for implementing Do Not Track. Consequently, the Parley Pro Websites 
            do not respond to a particular browser’s “Do Not Track” signals and we cannot guarantee that no tracking will occur.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            California Privacy Laws
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            California residents may have the right to access their information, the right to request deletion, and the right to know what information we disclose 
            to third parties for business purposes. Requests for access, deletion, and to know what we share with others should be submitted as described in this 
            document. We will not discriminate against California residents who exercise their rights under this section.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Children’s Online Privacy Protection Act Compliance
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity does not knowingly collect any information from anyone under 13 years of age. Our Websites, products and services are all directed to 
            people who are at least 13 years of age or older.
        </Typography>
        <Typography variant="h5" component="h2" className={classes.paragraph}>
            Changes to this Privacy Policy
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity will occasionally update this Privacy Policy to reflect updated business practices, company and customer feedback, and changing laws. 
            DanceClarity will prominently post a notice on the Websites when such update is made and encourages you to periodically review the Privacy Policy to be 
            informed of how DanceClarity is protecting your information.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Please contact us at contact@danceclarity.com with any questions regarding this Privacy Policy.
        </Typography>

    </Container>
    )
}

const PrivacyPage = () => {

    
    return (
        <Layout>
            <PrivacyPageTemplate/>
        </Layout>
    )
}

export default PrivacyPage;